<!-- 关于我们 -->
<template>
  <div class="about" id="about" name="about">
    <div class="about-content">
      <el-row :gutter="20" >
        <el-col :span="24">
          <router-link
            :to="{
              path: '/software',
            }"
          >
            <el-carousel height="350px">
              <el-carousel-item :interval="1500">
                <el-image
                  fit="fill"
                  :src="require('@/assets/imgs/about/banner1.png')"
                />
              </el-carousel-item>
              <el-carousel-item :interval="1500">
                <el-image
                  fit="fill"
                  :src="require('@/assets/imgs/about/banner2.png')"
                />
              </el-carousel-item>
              <el-carousel-item :interval="1500">
                <el-image
                  fit="fill"
                  :src="require('@/assets/imgs/about/banner3.png')"
                />
              </el-carousel-item>
              <el-carousel-item :interval="1500">
                <el-image
                  fit="fill"
                  :src="require('@/assets/imgs/about/banner4.png')"
                />
              </el-carousel-item>
              <el-carousel-item :interval="1500">
                <el-image
                  fit="fill"
                  :src="require('@/assets/imgs/about/banner5.png')"
                />
              </el-carousel-item>
            </el-carousel>
            <div style="position: relative; bottom: 120px; left: 90px">
              <el-button
                type="warning"
                size="large"
                style="font-size: 18px; letter-spacing: 0.5em; cursor: pointer"
                >桌面端下载</el-button
              >
            </div>
          </router-link>
        </el-col>
        <el-col :span="24">
          <p
            style="
              line-height: 30px;
              font-size: 18px;
              font-weight: bold;
              text-indent: 2em;
              padding: 50px 0 20px 0;
            "
          >
            毛绒之家（玩麻豆）是一家服务于中小型玩具企业和淘宝商家的综合性服务平台。平台集产品展示，图片下载和商品代发于一体，通过多样的产品和周到的服务，为销售商家提供更多更好的选择，为中小玩具企业创造更多赢利空间。
          </p>
        </el-col>
        <el-col :span="24">
          <el-row :gutter="30" style="padding: 30px 0">
            <el-col :span="6" >
              <el-card shadow="hover" style="text-align: center">
                <el-image
                  fit="fill"
                  :src="require('@/assets/imgs/about/pay0.png')"
                />
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card shadow="hover" style="text-align: center">
                <el-image
                  fit="fill"
                  :src="require('@/assets/imgs/about/pay1.png')"
                />
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card shadow="hover" style="text-align: center">
                <el-image
                  fit="fill"
                  :src="require('@/assets/imgs/about/pay2.png')"
                />
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card shadow="hover" style="text-align: center">
                <el-image
                  fit="fill"
                  :src="require('@/assets/imgs/about/pay3.png')"
                />
              </el-card>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row :gutter="30" style="padding: 30px 0">
            <el-col :span="6" >
              <div style="text-align: center; ">
                <i class="el-icon-view" style="font-size: 50px"></i>
                <p
                  style="font-size: 18px; line-height: 30px; padding-top: 20px"
                >
                  智能化的产品信息管理
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div style="text-align: center; ">
                <i class="el-icon-search" style="font-size: 50px"></i>
                <p
                  style="font-size: 18px; line-height: 30px; padding-top: 20px"
                >
                  及时准确的数据实时查询
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div style="text-align: center; ">
                <i class="el-icon-refresh" style="font-size: 50px"></i>
                <p
                  style="font-size: 18px; line-height: 30px; padding-top: 20px"
                >
                  行业特性的颜色、尺码管理
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div style="text-align: center; ">
                <i class="el-icon-download" style="font-size: 50px"></i>
                <p
                  style="font-size: 18px; line-height: 30px; padding-top: 20px"
                >
                  提供最新最快的图片下载
                </p>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
  methods: {
  },
};
</script>
<style scoped>
.about {
  background: url("../assets/imgs/download/pc-bg.jpg");
}
.about .about-header {
  height: 64px;
  border-bottom: 2px solid #ff6700;
}
.about .about-header .about-title {
  width: 1225px;
  margin: 0 auto;
  height: 64px;
  line-height: 64px;
  font-size: 28px;
}
.about .content {
  padding: 20px 0;
  width: 1225px;
  margin: 0 auto;
}
.about .content .goods-list {
  margin-left: -13.7px;
  overflow: hidden;
}
.about .about-content {
  width: 1225px;
  margin: 0 auto;
}
</style>
